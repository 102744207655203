// AppModel.js
import { atom, selector } from 'recoil';
import flagEnglish from '../asset/flag_english.svg';
import flagKorean from '../asset/flag_korean.svg';
import flagSpanish from '../asset/flag_spanish.svg';
import SvgBenefitSaveDebug from '../asset/ben_save_debug.svg';
import SvgBenefitSaveTime from '../asset/ben_save_time.svg';
import SvgBenefitTeamwork from '../asset/ben_teamwork.svg';
import SvgBenefitPattern from '../asset/ben_pattern.svg';

export const UserLanguage = {
    english:{code:'en-US', index:0, label:'English', src: flagEnglish, key:'english', menuCode:'en'},
    korean:{code:'ko-KR', index:1, label:'한국어', src: flagKorean, key:'korean', menuCode:'ko'},
    spanish:{code:'es-ES', index:2, label:'Español', src: flagSpanish, key:'spanish', menuCode:'es'},
};
const languageAtom = atom({default:{key:UserLanguage.english.key, label:UserLanguage.english.label}, key:'language'});
export {languageAtom};

export const HomeText = {
    VzmAims: {
        title: {
            // Our aim
            korean: "DbdbTap의 지향점",
            english: "Vision of DbdbTap",
            spanish: "Visión de DbdbTap"
        },
        aims: {
            practicality: {
                key: 'practicality',
                title: {
                    korean: "쉽고 실용적인...",
                    english: "Easy and Practical",
                    spanish: "Fácil y Práctico"
                },
                say: {
                    korean: <div>
                        <p>데이터베이스 스키마 정보를 템플릿에 적용하여
                            신속하게 초기 소스코드를 생성함으로써 시간을 절약하는 것,
                            그러한 작업이 부담되지 않고 쉽게 여겨지도록 하는 것이 가장 중요한 목표입니다.
                        </p>
                    </div>,
                    english: <div>
                        <p>
                            Saving time by quickly generating initial source codes
                            by applying database schema information to templates,
                            and making such work not burdensome and easy is the most important goal.
                        </p>
                    </div>,
                    spanish: <div>
                        <p>
                            Ahorrar tiempo generando rápidamente códigos fuente iniciales
                            aplicando información del esquema de la base de datos a plantillas,
                            y hacer que dicho trabajo no sea una carga y sea fácil es el objetivo más importante.
                        </p>
                    </div>
                },
                position: {top: 170, left: 220}
            },
            bigpicture: {
                key: 'bigpicture',
                title: {
                    korean: "큰 그림 이해하기",
                    english: "Big Picture",
                    spanish: "Panorama General"
                },
                say: {
                    korean: <div>
                        <p>프로그래머는 자신의 경력과 위치에 관계없이
                            프로젝트 전체에 대한 큰 그림을 이해하고 작업에 참여할 수 있어야 합니다.<br/>
                            DbdbTap이 이에 도움이 되고자 합니다.
                        </p>
                    </div>,
                    english: <div>
                        <p>Programmers should be able to understand the big picture of the entire project
                            and participate in the work regardless of their career and position.<br/>
                            DbdbTap to help with this.
                        </p>
                    </div>,
                    spanish: <div>
                        <p>Los programadores deben poder comprender el panorama general de todo el proyecto
                            y participar en el trabajo independientemente de su carrera y posición.<br/>
                            DbdbTap tiene como objetivo ayudar con esto.
                        </p>
                    </div>
                },
                position: {top: 120, left: 320},
            },
            teamwork: {
                key: 'teamwork',
                title: {
                    korean: "팀워크",
                    english: "Teamwork",
                    spanish: "Trabajo en equipo"
                },
                say: {
                    korean: <div>
                        <p>
                            데이터에 대해서,
                            서로의 코드에 대해서 이해하는 것,
                            그로써 함께 일하는 것이 편안해 지도록 하는 것 역시
                            이 서비스가 추구하는 것입니다.
                        </p>
                    </div>,
                    english: <div>
                        <p>
                            Understanding data and each other's codes
                            will make it easier to work together.
                            This is also what this service pursues.
                        </p>
                    </div>,
                    spanish: <div>
                        <p>
                            Comprender los datos y los códigos de los demás
                            facilitará trabajar juntos.
                            Esto también es lo que persigue este servicio.
                        </p>
                    </div>

                },
                position: {top: 240, left: 220}
            }
        }
    },
    whatDoesVzmDo: {
        title: {
            korean: "DbdbTap을 사용하여 무엇을 할 수 있나요?",
            english: "What can you do with DbdbTap?",
            spanish: "¿Qué puedes hacer con DbdbTap?"
        },
        say: {
            korean: <div>
                <ul>
                    <li>템플릿을 만들고 이를 데이터베이스 스키마에 적용하여 초기 버전의 소스코드를 생성할 수 있습니다.</li>
                    <li>프로젝트를 만든 후 동료 개발자들을 초대하여 협업할 수 있습니다. </li>
                    <li>템플릿별로 편집자를 지정하여 업무를 나눌 수 있습니다.</li>
                    <li>데이터베이스와 시스템설계 정보의 공유가 이루어집니다.</li>
                    <li>필요한 경우 프로젝트를 다른 멤버 사용자에게 양도할 수 있습니다.</li>
                </ul>
            </div>,
            english: <div>
                <ul>
                    <li>You can create templates and apply them to the database schema 
                        to generate the initial version of the source code.</li>
                    <li>After creating a project, you can invite fellow developers to collaborate.</li>
                    <li>You can assign editors for each template to divide the work.</li>
                    <li>You can share database and system design information with your team.</li>
                    <li>If necessary, you can transfer the ownership of the project to other member of your team.</li>
                </ul>
            </div>,
            spanish: <div>
                <ul>
                    <li>Puede crear plantillas y aplicarlas al esquema de la base de datos
                        para generar la versión inicial del código fuente.</li>
                    <li>Después de crear un proyecto, puede invitar a otros desarrolladores a colaborar.</li>
                    <li>Puede asignar editores para cada plantilla para dividir el trabajo.</li>
                    <li>Puede compartir información de diseño de base de datos y sistema con su equipo.</li>
                    <li>Si es necesario, puede transferir la propiedad del proyecto a otro miembro de su equipo.</li>
                </ul>
            </div>
        }
    },
    whatDoYouGet: {
        title: {
            korean: "DbdbTap 사용으로 무엇을 얻을 수 있나요?",
            english: "What can you get with DbdbTap?",
            spanish: "¿Qué puedes obtener con DbdbTap?"
        },
        lines: [
            {
                say: {
                    korean: <div>
                        <p><b>시간을 절약합니다.</b></p>
                        <ul>
                            <li>대부분의 코드는 전형적인 디자인 패턴과 전형적인 클래스, 함수, 변수 이름, 타입명 등을 공유합니다.</li>
                            <li>이러한 특성을 이용하여 템플릿을 작성하고, 이를 데이터베이스 스키마에 적용하여
                                초기코드를 생성함으로써 소스코드 작성 시간을 현저하게 줄일 수 있습니다.
                            </li>
                            <li>
                                만일 동일한 템플릿을 여러 프로젝트에 사용한다면, 더욱 많은 시간을 절약할 수 있습니다.
                            </li>
                        </ul>
                    </div>,
                    english: <div>
                        <p><b>Save time</b></p>
                        <ul>
                            <li>Most codes share typical design patterns 
                                and typical class, function, variable names, type names, etc.</li>
                            <li>You can create templates using these characteristics.
                                Apply them to the database schema to generate initial codes.
                                This will reduce the time to write source codes significantly.
                            </li>
                            <li>
                                If you use the same template for multiple projects, you can save even more time.
                            </li>
                                
                        </ul>
                    </div>,
                    spanish: <div>
                        <p><b>Ahorra tiempo</b></p>
                        <ul>
                            <li>La mayoría de los códigos comparten patrones de diseño típicos
                                y nombres típicos de clase, función, variable, nombres de tipo, etc.</li>
                            <li>Puede crear plantillas utilizando estas características.
                                Aplíquelas al esquema de la base de datos para generar códigos iniciales.
                                Esto reducirá significativamente el tiempo para escribir códigos fuente.
                            </li>
                            <li>
                                Si utiliza la misma plantilla para varios proyectos, puede ahorrar aún más tiempo.
                            </li>
                        </ul>
                    </div>
                },
                image: SvgBenefitSaveTime
            },
            {
                say: {
                    korean: <div>
                        <p><b>디버깅 시간을 줄입니다.</b></p>
                        <ul>
                            <li>전형적이고 따분한 기본 코드 입력작업을 하다보면
                                단순 오타 교정을 위한 추가 타이핑이나
                                어이없는 버그를 남겨두는 일 등을
                                일상적으로 겪게 됩니다.
                            </li>
                            <li>템플릿을 사용하여 작성된 코드는 오타 발생을 원천적으로 차단하는 효과가 있습니다.</li>
                            <li>적어도 이렇게 생성한 초기코드에 대해서만큼은 오타로 인한 디버깅 시간을 크게 줄여줄 것입니다.</li>
                        </ul>
                    </div>,
                    english: <div>
                        <p><b>Reduce debugging time</b></p>
                        <ul>
                            <li>Writing typical and boring basic codes
                                leads to additional typing for simple typo correction
                                or leaving silly bugs in your files.
                            </li>
                            <li>Codes written using templates prevent typos at the source.</li>
                            <li>It will greatly reduce debugging time due to typos
                                for the initial codes created in this way.
                            </li>
                        </ul>
                    </div>,
                    spanish: <div>
                        <p><b>Reduce el tiempo de depuración</b></p>
                        <ul>
                            <li>Escribir códigos básicos típicos y aburridos
                                conduce a una escritura adicional para corregir errores tipográficos simples
                                o dejar errores tontos en sus archivos.
                            </li>
                            <li>Los códigos escritos utilizando plantillas previenen errores tipográficos en la fuente.</li>
                        </ul>
                    </div>
                },
                image: SvgBenefitSaveDebug
            },
            {
                say: {
                    korean: <div>
                        <p><b>코드의 일관성 유지</b></p>
                        <ul>
                            <li>템플릿을 사용하여 작성된 코드는 프로젝트 전체에서 일관성을 유지합니다.</li>
                            <li>프로젝트가 진행되는 동안 템플릿을 사용하면 코드가 프로그래머의 일시적인 생각에 따라<br/>
                                디자인 패턴에서 벗어나는 것을 막아줍니다.
                            </li>
                        </ul>
                    </div>,
                    english: <div>
                        <p><b>Maintain code consistency</b></p>
                        <ul>
                            <li>Codes written using templates maintain consistency throughout the project.</li>
                            <li>Using templates during the project prevents codes from deviating from design patterns<br/>
                                according to the programmer's temporary thoughts.
                            </li>
                        </ul>
                    </div>,
                    spanish: <div>
                        <p><b>Mantener la consistencia del código</b></p>
                        <ul>
                            <li>Los códigos escritos utilizando plantillas mantienen la consistencia en todo el proyecto.</li>
                            <li>Utilizar plantillas durante el proyecto evita que los códigos se desvíen de los patrones de diseño<br/>
                                según los pensamientos temporales del programador.
                            </li>
                        </ul>
                    </div>
                },
                image: SvgBenefitPattern
            },
            {
                say: {
                    korean: <div>
                        <p><b>팀워크 향상</b></p>
                        <ul>
                            <li>DbdbTap을 사용하면 팀원들이 다른 분야의 프로그래머들이 사용하는 디자인 패턴을 이해할 수 있습니다.</li>
                            <li>데이터베이스 스키머 정보가 지속적으로 입력되는 것 역시 팀원들 사이의 소통이 매끄럽게 되도록 도움을 줍니다.</li>
                        </ul>
                    </div>,
                    english: <div>
                        <p><b>Enhance teamwork</b></p>
                        <ul>
                            <li>Using DbdbTap, team members can understand the design patterns used by programmers in other fields.</li>
                            <li>Continuous input of database schema information also
                                helps smooth communication among team members.</li>
                        </ul>
                    </div>,
                    spanish: <div>
                        <p><b>Mejorar el trabajo en equipo</b></p>
                        <ul>
                            <li>Usando DbdbTap, los miembros del equipo pueden entender los patrones de diseño utilizados por programadores en otros campos.</li>
                            <li>La entrada continua de información del esquema de la base de datos también
                                ayuda a una comunicación fluida entre los miembros del equipo.</li>
                        </ul>
                    </div>
                },
                image: SvgBenefitTeamwork
            }
        ]
    },
    whatDoIDo: {
        title: {
            korean: "서비스를 이용하려면 어떻게 하나요?",
            english: "How do I use the service?",
            spanish: "¿Cómo uso el servicio?"
        },
        say: {
            korean: <div>
                <ul>
                    <li>먼저, 서비스에 가입합니다.
                        서비스 가입에 필요한 유일한 조건은 구글 계정을 가지고 있어야 하는 것 뿐입니다.</li>
                    <li>몇가지 간단한 내용을 입력하여 프로젝트를 만듭니다.</li>
                    <li>프로젝트에서 데이터베이스에 접근하여 스키마 정보를 읽을 수 있도록 설정합니다.<br/>
                        DbdbTap에서
                        데이터베이스에 접근하는 방법은 두가지입니다.
                        <ol>
                            <li>첫째는 DbdbTap에 내장되어 있는 JDBC를 통하는 것(그림)입니다.</li>
                            <li>둘째는 DbdbTap에 직접 스키마 정보를 입력하는 것입니다.</li>
                        </ol>
                    </li>
                    <li>혼자 일하는 것이 아니라면 동료들을 초대합니다.</li>
                    <li>설계에 따라 필요한 템플릿들을 작성합니다.
                        템플릿은 아키텍쳐에 따라 적절한 그룹들로 나눕니다.</li>
                    <li>템플릿을 각 테이블에 적용해서 소스코드를 생성합니다.</li>
                    <li>자세한 내용은 <a
                        href="/doc/userguide/index.html?lang=korean">사용 안내</a>와 <a
                        href="/doc/vzmref/index.html?lang=korean">템플릿 설명서</a>를 참조하세요.
                    </li>
                </ul>
            </div>,
            english: <div>
                <ul>
                    <li>First, sign up for the service.
                        The only condition for signing up for the service is having a Google account.</li>
                    <li>Enter some simple information to create a project.</li>
                    <li>Add configuration to allow access to the database schema in the project.<br/>
                        There are two ways to access the database in DbdbTap.
                        <ol>
                            <li>The first one is through JDBC integrated in DbdbTap (image),</li>
                            <li>the second is entering database schema information directly into DbdbTap.</li>
                        </ol>
                    </li>
                    <li>Invite your colleagues if you are not working alone.</li>
                    <li>Write templates needed according to the design.
                        Divide the templates into appropriate groups according to the architecture.</li>
                    <li>Apply the templates to each table to generate source codes.</li>
                    <li>For more information, see <a
                        href="/doc/userguide/index.html?lang=english">the user guide</a> and <a
                        href="/doc/vzmref/index.html?lang=english">the template reference</a>.
                    </li>
                </ul>
            </div>,
            spanish: <div>
                <ul>
                    <li>Primero, regístrese en el servicio.
                        La única condición para registrarse en el servicio es tener una cuenta de Google.</li>
                    <li>Ingrese información simple para crear un proyecto.</li>
                    <li>Agregue configuración para permitir el acceso al esquema de la base de datos en el proyecto.<br/>
                        Hay dos formas de acceder a la base de datos en DbdbTap.
                        <ol>
                            <li>La primera es a través de JDBC integrado en DbdbTap (imagen),</li>
                            <li>la segunda es ingresar información del esquema de la base de datos directamente en DbdbTap.</li>
                        </ol>
                    </li>
                    <li>Invite a sus colegas si no está trabajando solo.</li>
                    <li>Escriba plantillas necesarias de acuerdo con el diseño.
                        Divida las plantillas en grupos apropiados según la arquitectura.</li>
                    <li>Aplique las plantillas a cada tabla para generar códigos fuente.</li>
                    <li>Para obtener más información, consulte <a
                        href="/doc/userguide/index.html?lang=spanish">la guía del usuario</a> y <a 
                        href="/doc/vzmref/index.html?lang=spanish">el documento de referencia</a>.</li>
                </ul>
            </div>
        }
    }
};

/*


    whatDoIDo: {
        title: {
            korean: "서비스를 이용하려면 어떻게 하나요?",
            english: "How do I use the service?",
            spanish: "¿Cómo uso el servicio?"
        },
        say: {
            korean: <div>
                <ul>
                    <li>먼저, 서비스에 가입합니다.
                        서비스 가입에 필요한 유일한 조건은 구글 계정을 가지고 있어야 하는 것 뿐입니다.</li>
                    <li>몇가지 간단한 내용을 입력하여 프로젝트를 만듭니다.</li>
                    <li>프로젝트에서 데이터베이스에 접근하여 스키마 정보를 읽을 수 있도록 설정합니다.<br/>
                        DbdbTap에서
                        데이터베이스에 접근하는 방법은 세가지입니다.
                        <ol>
                            <li>첫째는 RDA(Relational Database Adapter)를 통하는 것(왼쪽 그림)이고,</li>
                            <li>둘째는 DbdbTap에 내장되어 있는 JDBC를 통하는 것(오른쪽 그림)입니다.</li>
                            <li>마지막은 DbdbTap에 직접 스키마 정보를 입력하는 것입니다.</li>
                        </ol>
                        RDA를 사용하려면 먼저 RDA를 다운로드 받거나 RDA 도커를 받아 설치하고 적절한 설정을 해야합니다.
                    </li>
                    <li>혼자 일하는 것이 아니라면 동료들을 초대합니다.</li>
                    <li>설계에 따라 필요한 템플릿들을 작성합니다.
                        템플릿은 아키텍쳐에 따라 적절한 그룹들로 나눕니다.</li>
                    <li>템플릿을 각 테이블에 적용해서 소스코드를 생성합니다.</li>
                    <li>자세한 내용은 <a
                        href="/doc/userguide/index.html?lang=korean">사용 안내</a>와 <a
                        href="/doc/vzmref/index.html?lang=korean">템플릿 설명서</a>를 참조하세요.
                    </li>
                </ul>
            </div>,
            english: <div>
                <ul>
                    <li>First, sign up for the service.
                        The only condition for signing up for the service is having a Google account.</li>
                    <li>Enter some simple information to create a project.</li>
                    <li>Add configuration to allow access to the database schema in the project.<br/>
                        There are three ways to access the database in DbdbTap.
                        <ol>
                            <li>The first one is through RDA (Relational Database Adapter) (left image),</li>
                            <li>the second is through JDBC integrated in DbdbTap (right image),</li>
                            <li>and the last is entering database schema information directly into DbdbTap.</li>
                        </ol>
                        To use RDA, you need to download RDA or install RDA Docker and configure it properly.
                    </li>
                    <li>Invite your colleagues if you are not working alone.</li>
                    <li>Write templates needed according to the design.
                        Divide the templates into appropriate groups according to the architecture.</li>
                    <li>Apply the templates to each table to generate source codes.</li>
                    <li>For more information, see <a
                        href="/doc/userguide/index.html?lang=english">the user guide</a> and <a
                        href="/doc/vzmref/index.html?lang=english">the template reference</a>.
                    </li>
                </ul>
            </div>,
            spanish: <div>
                <ul>
                    <li>Primero, regístrese en el servicio.
                        La única condición para registrarse en el servicio es tener una cuenta de Google.</li>
                    <li>Ingrese información simple para crear un proyecto.</li>
                    <li>Agregue configuración para permitir el acceso al esquema de la base de datos en el proyecto.<br/>
                        Hay tres formas de acceder a la base de datos en DbdbTap.
                        <ol>
                            <li>La primera es a través de RDA (Relational Database Adapter) (imagen izquierda),</li>
                            <li>la segunda es a través de JDBC integrado en DbdbTap (imagen derecha),</li>
                            <li>y la última es ingresar información del esquema de la base de datos directamente en DbdbTap.</li>
                        </ol>
                        Para usar RDA, debe descargar RDA o instalar RDA Docker y configurarlo correctamente.
                    </li>
                    <li>Invite a sus colegas si no está trabajando solo.</li>
                    <li>Escriba plantillas necesarias de acuerdo con el diseño.
                        Divida las plantillas en grupos apropiados según la arquitectura.</li>
                    <li>Aplique las plantillas a cada tabla para generar códigos fuente.</li>
                    <li>Para obtener más información, consulte <a
                        href="/doc/userguide/index.html?lang=spanish">la guía del usuario</a> y <a 
                        href="/doc/vzmref/index.html?lang=spanish">el documento de referencia</a>.</li>
                </ul>
            </div>
        }
    }



*/
