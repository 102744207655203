// LangSelector.js
import React, { useEffect, useRef, useState } from 'react';
import { Box, Button,  } from '@mui/material';
import { styled } from '@mui/material/styles';
import { UserLanguage, languageAtom } from '../../value/AppModel';
import { useSetRecoilState, useRecoilValue, useRecoilState } from "recoil";
import { usePolyglot } from '../../api/vzm-hook';

const SelectorBox = styled(Box)({
    position:'fixed', top:0, right:100,
    fontSize:'0.8rem', backgroundColor:'#151548', borderBottomLeftRadius:10, borderBottomRightRadius:10,
    padding: '2px 10px 3px 10px',
    display: 'flex', flexDirection: 'row', alignItems: 'center',
});

const LangButton = styled(Button)({
    textTransform: 'none !important', color: '#9af'
});

const LangSpan = styled('span')({
    color: '#fff', margin: '0 12px 0 12px',
    // add underline
    textDecoration: 'underline',
});

export default function LangSelector() {
    const [language, setLanguage] = useRecoilState(languageAtom);
    const [langArray, setLangArray] = useState([]);
    const polyglot = usePolyglot();

    useEffect(()=>{
        setLangArray(Object.values(UserLanguage));
    }, []);

    const handleLangChange = (lang) => {
        setLanguage({key:lang.key, label: lang.label});
        window.setMenuUsingLanguage(lang.menuCode);
    };

    const renderButton = (lang) => {
        if(lang.key === language.key) {
            return <LangSpan key={lang.key}>{lang.label}</LangSpan>
        }
        return (
            <LangButton
                key={lang.key}
                onClick={()=>handleLangChange(lang)}
                variant='text'
                size='small'
            >
                {lang.label}
            </LangButton>
        );
    }

    return (
        <SelectorBox>
            {
                langArray.map(lang => renderButton(lang))
            }
        </SelectorBox>
    );
}


